import { Box, Grid } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
import React from "react"
import dpcgclogo from "../images/dpcgc/dpcgclogo.svg"
import grbmember1 from "../images/grbmember1.svg"
import grbmember3 from "../images/grbmember3.svg"
import grbmember5 from "../images/grbmember5.svg"
import grbmember6 from "../images/grbmember6.svg"
import grbmember7 from "../images/grbmember7.png"
import grbmember8 from "../images/grbmember8.jpg"

const useStyles = makeStyles(theme => ({
  rooot: {
    width: "100%",
    background: "#243974",
    // height: "22rem",
    padding: "8.5rem 0 2rem 0",
  },
  dpcgclogo: {
    height: "11rem",
  },
  iamailogo: {
    height: "4rem",
    margin: "2rem",
  },
  nav: {
    color: "#243974",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  grid: {
    margin: "10px 40px",
    [theme.breakpoints.down("md")]: {
      margin: "0px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px 10px",
    },
  },
  othernav: {
    color: "#4F4F4F",
    fontSize: "14px",
    lineHeight: "16px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  bigheading: {
    // width:"150px",
    // height:"30px",
    FontFace: "Roboto",
    fontSize: "26px",
    lineHeight: "30px",
    color: "#243974",
    padding: "10px",
    marginleft: "334px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "30px",
      //   padding: "0 20px 0 15px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "30px",
      //   padding: "0 20px 0 15px",
    },
  },
  member: {
    height: "15rem",
    marginTop: "25px",
  },
  text: {
    color: "#000000",
    fontSize: "16px",
    lineHeight: "20px",

    // [theme.breakpoints.down("xs")]: {
    //   padding: "0 29px",
    //   fontSize: "10px",
    //   lineHeight: "20px",
    // },
    [theme.breakpoints.down("md")]: {
      // padding: "0 59px",
      fontSize: "16px",
      lineHeight: "20px",
      paddingTop: "2px",
      paddingRight: "0",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingRight: "0 29px",
      fontSize: "12px",
      lineHeight: "20px",
    },
  },
  text1: {
    color: "#000000",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: "bold",

    [theme.breakpoints.down("sm")]: {
      // paddingRight: "0 29px",
      fontSize: "14px",
      lineHeight: "20px",
    },
    // [theme.breakpoints.down("xs")]: {
    //   padding: "0 29px",
    //   fontSize: "10px",
    //   lineHeight: "20px",
    // },
    [theme.breakpoints.down("md")]: {
      // padding: "0 59px",
      fontSize: "14px",
      lineHeight: "20px",
      // paddingTop:"2px",
      // paddingRight:"20px",
    },
  },
  leader: {
    borderRadius: "50%",
    height: "12rem",
    [theme.breakpoints.down("sm")]: {
      height: "30rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "30rem",
    },
    [theme.breakpoints.down("md")]: {
      height: "12rem",
    },
  },
  class: {
    // paddingLeft: "268px",
    // padding:"0 59px",
    [theme.breakpoints.down("sm")]: {
      // paddingRight: "0 29px",
      fontSize: "12px",
      lineHeight: "14px",
    },
    [theme.breakpoints.down("xs")]: {
      // padding: "0 29px",
      fontSize: "18px",
      lineHeight: "20px",
    },
    [theme.breakpoints.down("md")]: {
      // padding: "0 59px",
      fontSize: "18px",
      lineHeight: "22px",
      // paddingTop:"2px",
      // paddingRight:"20px",
    },
  },
  newicon: {
    height: "16px",
    [theme.breakpoints.down("md")]: {
      height: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "10px",
    },
  },
}))

export default function GRBMembers() {
  const classes = useStyles()
  const theme = {
    spacing: 8,
  }
  return (
    <>
      <Box className={classes.rooot}>
        <Grid container justifyContent="center">
          <Grid item>
            <Link to="/">
              <img
                src={dpcgclogo}
                alt="dpcgc logo"
                className={classes.dpcgclogo}
              />
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={0} justifyContent="center">
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/whoweare" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>ABOUT US</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/grbmembers" style={{ textDecoration: "none" }}>
            <Typography className={classes.nav}>GRB MEMBERS</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/govtnotification" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>
              GOVT. NOTIFICATIONS
            </Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/discolsures" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>DISCLOSURES</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/faqs" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>FAQS</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/leadersspeak" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>
              LEADERSHIP TEAM
            </Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          {/* <Box style={{ textAlign: "right" }}>
          <img src={newicon} alt="new" className={classes.newicon} />
          </Box> */}
          <Link to="/consumerresources" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>
              CONSUMER RESOURCES
            </Typography>
          </Link>
        </Grid>
      </Grid>
      <Box height="10rem" />
      <Grid container justifyContent="center">
        <Grid item sm={10} xs={10} md={8} lg={7}>
          <Typography className={classes.bigheading}>GRB Members</Typography>
        </Grid>
        <Grid item sm={10} xs={10} md={8} lg={7}>
          <Grid container justifyContent="flex-start" className={classes.class}>
            <Grid item md={6} xs={12} sm={6}>
              <Link to="/grbmember1" style={{ textDecoration: "none" }}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ padding: "20px 10px" }}
                >
                  <Grid item md={5} xs={4} sm={4}>
                    <img src={grbmember1} className={classes.leader} />
                  </Grid>
                  <Grid item md={7} xs={8} sm={8} style={{ padding: "10px" }}>
                    <Typography className={classes.text1}>
                      Justice Arjan Kumar Sikri
                    </Typography>
                    <Typography className={classes.text}>
                      Chairman, Grievance
                    </Typography>
                    <Typography className={classes.text}>
                      Redressal Board, DPCGC
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </Grid>
            <Grid item md={6} xs={12} sm={6}>
              <Link to="/grbmember3" style={{ textDecoration: "none" }}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ padding: "20px 10px" }}
                >
                  <Grid item md={5} xs={4} sm={4}>
                    <img src={grbmember3} className={classes.leader} />
                  </Grid>
                  <Grid item md={7} xs={8} sm={8} style={{ padding: "10px" }}>
                    <Typography className={classes.text1}>
                      Madhu Bhojwani
                    </Typography>
                    <Typography className={classes.text}>
                      Co-Founder & Partner –
                    </Typography>
                    <Typography className={classes.text}>
                      Emmay Entertainment
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </Grid>
            <Grid item md={6} xs={12} sm={6}>
              <Link to="/grbmember5" style={{ textDecoration: "none" }}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ padding: "20px 10px" }}
                >
                  <Grid item md={5} xs={4} sm={4}>
                    <img src={grbmember5} className={classes.leader} />
                  </Grid>
                  <Grid item md={7} xs={8} sm={8} style={{ padding: "10px" }}>
                    <Typography className={classes.text1}>
                      Suhasini Mani Ratnam
                    </Typography>
                    <Typography className={classes.text}>
                      Director, Producer / Head,
                    </Typography>
                    <Typography className={classes.text}>
                      Organizing Committee Chennai
                    </Typography>
                    <Typography className={classes.text}>
                      International Film Festival
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </Grid>
            <Grid item md={6} xs={12} sm={6}>
              <Link to="/grbmember6" style={{ textDecoration: "none" }}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ padding: "20px 10px" }}
                >
                  <Grid item md={5} xs={4} sm={4}>
                    <img src={grbmember6} className={classes.leader} />
                  </Grid>
                  <Grid item md={7} xs={8} sm={8} style={{ padding: "10px" }}>
                    <Typography className={classes.text1}>
                      Amit Grover
                    </Typography>
                    <Typography className={classes.text}>
                      Senior Corporate Counsel,
                    </Typography>
                    <Typography className={classes.text}>
                      Amazon India
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </Grid>
            <Grid item md={6} xs={12} sm={6}>
              <Link to="/grbmember7" style={{ textDecoration: "none" }}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ padding: "20px 10px" }}
                >
                  <Grid item md={5} xs={4} sm={4}>
                    <img src={grbmember7} className={classes.leader} />
                  </Grid>
                  <Grid item md={7} xs={8} sm={8} style={{ padding: "10px" }}>
                    <Typography className={classes.text1}>
                      Kiran Desai
                    </Typography>
                    <Typography className={classes.text}>
                      Director- India General Councel
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </Grid>
            <Grid item md={6} xs={12} sm={6}>
              <Link to="/grbmember8" style={{ textDecoration: "none" }}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ padding: "20px 10px" }}
                >
                  <Grid item md={5} xs={4} sm={4}>
                    <img src={grbmember8} className={classes.leader} />
                  </Grid>
                  <Grid item md={7} xs={8} sm={8} style={{ padding: "10px" }}>
                    <Typography className={classes.text1}>
                      Ajay Khanna
                    </Typography>
                    <Typography className={classes.text}>
                      Advisor, Independent Director. Ombudsperson, Professor of
                      Practice.
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box height="15rem" />
      {/* <Footer /> */}
    </>
  )
}
